<template>
  <SectionTextImageBasic
    v-bind="$props"
  >
    <UiLoader
      :class="[(loading || !(eventViews?.length)) ? 'pb-10 lg:pt-8' : 'pb-10 lg:pt-1']"
      :has-data="!!(eventViews.length)"
      :loading="loading"
      empty-message="Er zijn op dit moment geen evenementen."
    >
      <ul class="divide-y divide-black-900 dark:divide-white">
        <li
          v-for="eventView in eventViews"
          :key="eventView.event.uuid"
        >
          <NuxtLink
            :to="{name: 'evenementen-uuid-date', params: {uuid: eventView.event.uuid, date: eventView.date}}"
            class="link flex items-center gap-11 py-3 font-extrabold lg:gap-20"
          >
            <div class="lg:tracking-wide">
              <FormatDate
                format="website-medium"
                :value="eventView.date"
              />
            </div>
            {{ eventView.event.title }}
          </NuxtLink>
        </li>
      </ul>
    </UiLoader>

    <div class="flex justify-center lg:justify-start">
      <NuxtLink
        to="evenementen"
        class="btn-xs btn-primary lg:btn-lg"
      >
        Bekijk hele agenda
      </NuxtLink>
    </div>
  </SectionTextImageBasic>
</template>

<script lang="ts" setup>
import {useEventsQuery} from '@graphql/graphql';
import type {SectionProps} from '~/types/section';

const props = defineProps<SectionProps>();

const {result, loading} = useEventsQuery(() => ({
  limit: 5,
  types: props.event_types,
}));

const eventViews = computed(() => result.value?.events?.data ?? []);
</script>
